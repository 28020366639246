/* -- Blue
Blue 0: #39D5FF  80% sat - #4dcaeb
Blue 1: #29C5FF
Blue 2: #19B5FE
Blue 3: #22A7F0
Blue 4: #1297E0
Blue 5: #0287D0  80% sat - #1581bd
Blue 6: #0077C0
Blue 7: #0067B0
Blue 8: #0057A0
Blue 9: #004790
Blue 10: #003780
Blue 11: #102770
 */

/* Paste in <head> of index.html */
/* <link
      href="https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&display=swap"
      rel="stylesheet"
    /> */

/* Organizational */
* {
  padding: 0;
  margin: 0;
  text-align: center;
}

body {
  /* background color 0 */
  background-color: #6cb8d9;
  /* background-color: black; */
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spacer10 {
  padding: 0;
  height: 10px;
}

.spacer5 {
  padding: 0;
  height: 5px;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  color: #427aa9;
  text-align: center;
}

p {
  color: #427aa9;
  text-align: left;
  letter-spacing: -0.5px;
  line-height: 1.3em;
  font-size: 1.15em;
}

.bold {
  font-weight: 700;
}

.text-block {
  width: 90%;
  margin: 10px auto;
  text-align: left;
}

a {
  text-decoration: none;
}

/* a:visited {
  text-decoration: none;
  color: white;
} */

.container-invisible {
  width: 35%;
  padding: 10px;
  margin: 0 auto;
}

.container-outer {
  width: 35%;
  padding: 10px;
  margin: 0 auto;
  /* background color 5 */
  background-color: #1581bd;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d6f3fa;
}

.container-inner {
  width: 90%;
  margin: 10px auto 5px;
  /* background color 2 */
  background-color: white;
  padding: 10px;
  border-style: solid;
  border-color: #d6f3fa;
  border-radius: 10px;
  border-width: 1px;
}

.copy-block {
  margin: 10px;
}

.dark {
  /* color 0 */
  color: #17a8ce;
  background-color: #1581bd;
}

.light {
  color: #a3e4f5;
}

.header {
  margin: 0 auto;
  padding: 30px 0 10px;
  width: 50%;
}

.header img {
  width: 50%;
}

/* Lists: */

ul {
  width: 80%;
  border-radius: 5px;
  /* background color 11 */
  background-color: #c5d3e2;
  padding: 10px;
  margin: 10px auto;
  list-style: none;
}

li {
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  margin: 5px;
  /* color 0 */
  color: #1c2a39;
}

/* Tables: */

table {
  padding: 10px;
  margin: 10px auto 20px;
  width: 95%;
  background-color: white;
}

th {
  text-align: left;
  font-weight: 500;
  /* color 0 */
  color: black;
}

tr {
  text-align: left;
  font-weight: 400;
  /* color 0 */
  color: black;
}

td {
  text-align: left;
}

/* Button (General Use) */

button {
  font-weight: 400;
  background-color: #2da0e0;
  color: white;
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #d6f3fa;
  margin: 0 10px;
  transition: 0.3s;
}

.big {
  background-color: #1581bd;
  font-size: 1.5em;
  height: 50px;
  width: 200px;
  border-radius: 10px;
}

button:hover {
  background-color: #a0deee;
  color: white;
}

button:active {
  position: relative;
  top: 1px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.4);
}

/* Form (General Use)*/

.h-form {
  width: 85%;
  margin: 0 auto;
  /* border color 9 */
  border: 1px solid #acbac9;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
}

.h-form .h-label {
  color: #427aa9;
  display: block;
  padding: 7px;
  box-sizing: border-box;
  /* background color 8 */
  background-color: #a0deee;
}

.h-form .h-input {
  font-family: "Roboto Condensed", sans-serif;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  padding: 15px;
  font-size: 14px;
}

.h-form .h-btn {
  /* background color 6 */
  background-color: #7c8a99;
  color: white;
  display: block;
  margin: 15px auto;
  border: none;
  padding: 4px 10px;
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  transition: 0.3s;
}

.h-form .h-btn:hover {
  /* background color 8 */
  background-color: #9caab9;
  cursor: pointer;
}

.h-form .h-btn:active {
  /* background color 4 */
  background-color: #5c6a79;
}

.h-textarea {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  width: 99%;
  height: 50px;
}

/* Transitions */

/* This fires as soon as the element enters the DOM */
.fade-enter,
.fade-appear {
  opacity: 0;
}
/* This is where we can add the transition*/
.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

/* This fires as soon as the condtion for the "in" prop is false */
.fade-exit {
  opacity: 1;
}
/* fires as element leaves the DOM*/
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
